import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS } from 'react-device-detect';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/common/footer/Footer';
import CompanyHeader from '../components/common/company-header/CompanyHeader';
import FullHeightWrapper from '../components/common/full-height-wrapper/FullHeightWrapper';
import { GlobalState } from '../state/@types/redux.interface';
import PasswordInput from '../components/authorize/PasswordInput';
import BasicButton from '../components/common/basic-button/BasicButton';
import {
	passwordAuthorizeToEventAction,
} from '../state/authorize.reducer';
import KeySVG from '../images/authorize/key.svg';
import { resetCodeAuthorizeToEvent } from '../services/services';
import { setEventLoadingStatusAction } from '../state/event.reducer';
import { getLocalStorageItem } from '../_utils/localStorage';

const StyledLoginWrapper = styled.div`
	background: ${({ theme }) => theme.colors.white};
	border-bottom-left-radius: 35px;
	border-bottom-right-radius: 35px;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
    padding: 0 35px 40px;
	::-webkit-scrollbar {
		display: none;
	}
  @media(min-width: 1000px) {
    max-width: 400px;
    margin: auto;
	padding: 0;
  }
`;

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	left: 0;
	margin-bottom: 30px;
	width: 100%;
`;
const StyledText = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 0.75rem;
	line-height: 1.42rem;
`;
const StyledAuthorizeHeader = styled.h3`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 1.49rem;
	font-weight: 800;
	margin-bottom: 0.71rem;
`;
const AuthorizeHeaderImg = styled.img`
	margin-right: 10px;
`;
const LoginWrapper = styled.div`
	@media(max-width: 600px) {
		height: 100%;
		position: absolute;
		top: 0;
	  
		&.goTop {
			transition: all .3s;
			top: -170px;
		}
	  }
`;
const LostPasswordLink = styled(Link)`
	color: ${({ theme }) => theme.colors.fontBlue};
  	font-size: 0.75rem;
	margin-bottom: 20px;
`;

const LoginPage = () => {
	const eventHash = getLocalStorageItem('eventHash');

	const dispatch = useDispatch();
	const [password, setPassword] = useState<string>('');
	const [isError, setIsError] = useState<boolean>(null);
	const { voterEventAccess, error } = useSelector((state: GlobalState) => state.authorize);
	const [isTop, setTop] = useState(false);

	useEffect(() => {
		dispatch(setEventLoadingStatusAction(false));
	}, []);

	const onSubmit = async () => {
		if (password.length < 8 && password.length > 0) {
			setIsError(true);
		} else {
			dispatch(passwordAuthorizeToEventAction(eventHash, {
				password,
				login: getLocalStorageItem('login'),
			}));
		}
	};

	useEffect(() => {
		if (voterEventAccess) {
			dispatch(setEventLoadingStatusAction(true));
			navigate('/room');
		}
	}, [voterEventAccess]);

	const onKeyDown = (e) => {
		if (e.keyCode === 13) {
			onSubmit();
		}
	};
	const pushInputToTop = () => {
		if (!isIOS) {
			setTop(true);
		}
	};
	const handlePasswordReset = () => {
		resetCodeAuthorizeToEvent(eventHash, getLocalStorageItem('login'));
	};

	return (
		<Layout>
			<LoginWrapper className={isTop ? 'goTop' : ''}>
				<SEO
					description="Wyborek | Głosuj szybko i łatwo!"
					title="Zaloguj się"
				/>
				<CompanyHeader />
				<FullHeightWrapper>
					<StyledLoginWrapper>
						<StyledAuthorizeHeader>
							<AuthorizeHeaderImg
								alt="Key"
								src={KeySVG}
							/>
							Zaloguj się
						</StyledAuthorizeHeader>
						<StyledText>
							Podaj hasło stworzone wcześniej,
							{' '}
							pamiętaj aby
							{' '}
							<strong>nikomu go nie podawać</strong>
						</StyledText>

						<PasswordInput
							errorVisible={isError || error}
							label="Hasło"
							onKeyDown={onKeyDown}
							password={password}
							pushInputToTop={pushInputToTop}
							setPassword={(e) => setPassword(e.target.value)}
						/>
						<LostPasswordLink
							onClick={handlePasswordReset}
							state={{
								isPasswordForgotten: true,
								eventHash,
							}}
							to="/authorize"
						>
							Nie pamiętam hasła
						</LostPasswordLink>
					</StyledLoginWrapper>
					<StyledButtonWrapper>
						<BasicButton onClick={onSubmit}>Zaloguj się</BasicButton>
					</StyledButtonWrapper>
				</FullHeightWrapper>
				<Footer
					withRegulations
				/>
			</LoginWrapper>
		</Layout>
	);
};

export default LoginPage;
